<template>
  <Container>
    <div
      v-if="container_id || gallery_id || category"
      class="product-curalate-wrap"
    >
      <div v-if="container_id" :data-crl8-container-id="container_id" />
      <div
        v-else-if="gallery_id"
        :data-crl8-container-id="`gallery-${gallery_id}`"
      />
      <div
        v-else-if="category"
        data-crl8-container-id="category"
        :data-crl8-filter="filter"
      />
    </div>
  </Container>
</template>

<script>
  import { AlchemyElement } from "~/mixins/alchemyVue"

  export default {
    mixins: [AlchemyElement],
    computed: {
      container_id() {
        return this.getValue("container_id")
      },
      gallery_id() {
        return this.getValue("gallery_id")
      },
      category() {
        return this.getValue("category")
      },
      filter() {
        if (this.category) {
          return `category:'${this.category}'`
        }
        return null
      },
    },
    deactivated() {
      this.$unmount()
    },
  }
</script>

<style lang="scss">
  .product-curalate-wrap {
    min-height: $base-spacing * 77;
  }
</style>
